








































import Component, { mixins } from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import SygniRangeInput from "@/components/inputs/SygniRangeInput.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import SygniButtonsGroup from "@/components/layout/SygniButtonsGroup.vue";
// import {SelectOption} from "@/store/types";
import {Filter, FilterFunctions, FilterMixin, FilterOperators} from "@/shared/mixins/FilterMixin";
// import {AddProductSelects, InvestorSelects} from "@/modules/shared/dictionary-store/types";
import {AddProductSelects} from "@/modules/shared/dictionary-store/types";
import {mapGetters } from "vuex";
import {Statues} from "@/shared/interfaces/Statues";
import SygniCheckableButton from "@/components/buttons/SygniCheckableButton.vue";
import { Dictionaries } from '@/modules/genprox/modules/fund/modules/portfolio/store/types';
import { SelectOption } from '@/store/types';
import { Watch } from 'vue-property-decorator';
import Utils from '@/modules/shared/utils/utils';
import { _axios as axios } from "@/plugins/axios";
import { BACKEND_API_URL } from '@/shared/consts';

@Component({
  components: {
    SygniCheckableButton,
    SygniRangeInput,
    SygniButtonsGroup,
    SygniDatePicker, SygniContainerTitle, SygniRoundedButton, SygniSquareButton, SygniSelect, SygniInput, SygniMultiSelect
  },
  computed: {
    ...mapGetters('portfolio', {
      dictionaries: 'getDictionaries',
    })
  }
})
export default class TransactionsFilters extends mixins (FilterMixin) {
  productSelects!: AddProductSelects;
  dictionaries!: Dictionaries;
  readonly Statues = Statues;
  areDocumentNumberOptionsLoading: boolean = false;
  documentNumberOptions: any = [];
  counterPartyNameOptions: any = [];
  areCounterPartyNameOptionsLoading: boolean = false;
  date: string = '';
  itemsUrl: string = 'transaction';

  filters: Record<string, Filter> = {
    code: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.like, type: 'string' },
    registrationStatus: { value: null, getQueryValue: FilterFunctions.string, operator: FilterOperators.eq, type: 'string' },
    date: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    registrationDate: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    createdAt: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
    updatedAt: { value: null, getQueryValue: FilterFunctions.dateRange , operator: FilterOperators.like, type: 'dateRange' },
  };

  selectedDocumentStatusOptions: Array<SelectOption<string>> = [];

  get showLePicker() {
    if(this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company' && this.options.length > 1;
    } else {
      return this.activeUserData.context.context !== 'fund' && this.options.length > 1;
    }
  }

  get fundOptions(): Array<SelectOption<string>> {
    const funds = this.$store.getters['auth/getInvestedFunds'];

    if (!funds) return [];

    return funds.map((el: any) => {
      return {
        label: el.name,
        value: el.id
      }
    });
  }

  get companyOptions(): Array<SelectOption<string>> {
    const companies = this.$store.getters['auth/getInvestedCompanies'];

    if (!companies) return [];

    return companies.map((el: any) => {
      return {
        label: el.name,
        value: el.id
      }
    })
  }

  get options(): Array<SelectOption<string>> {
    return this.isPortfolioCompany ? this.companyOptions : this.fundOptions;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedId() {
    return this.isPortfolioCompany ? this.viewedCompanyId : this.viewedFundId;
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('/company') ? true : false;
  }

  setViewedId(id: string): void {
    if (id) {
      this.$emit('setViewedId', id);
    }
  }

  disabledDate(date: Date) {
    date = new Date(date);
    const now = new Date();
    return date > now ? true : false;
  }

  updateStatuses(statuses: Array<string>) {
    this.filters.status.value = statuses;
  }

  getDocumentStatusClassName(status: string): string {
    let className = 'primary';
    switch(status) {
      case('uploaded'):
        className = 'primary';
        break;
      case('processed'):
        className = 'success';
        break;
      case('sent-to-approval'):
        className = 'success';
        break;
      case('approved'):
        className = 'primary';
        break;
      case('booked'):
        className = 'primary';
        break;
      case('failed'):
        className = 'danger';
        break;
      case('rejected'):
        className = 'danger';
        break;
      case('rejected-by-operator'):
        className = 'danger';
        break;
      case('canceled'):
        className = 'danger';
        break;
      default:
        className = 'primary';
    }

    return className;
  }

  setDate(date: any): void {
    let dateTime = new Date(date);
    dateTime = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0);
    const dateRange = [date, `${dateTime.getFullYear()}-${Utils.pad(dateTime.getMonth() + 1, 2)}-${Utils.pad(dateTime.getDate(), 2)}`];
    this.filters.date.value = dateRange;
  }

  createSingleFilterQuery(filter: Filter, filterName: string): string {
    let query: string = this.createCustomQuery(filter, filterName);
    if(query !== '') return query;
    let queryValue = filter.getQueryValue(filter.value);
    if(queryValue === '') return '';
    switch(filter.type) {
      case('dateRange'):
        if(filter.value[0] === null || filter.value[1] === null) break;
        queryValue = filter.getQueryValue(filter.value,0);
        query += `&filters[${filterName}][0][value]=${queryValue}`;
        query += `&filters[${filterName}][0][operator]=gte`;
        queryValue = filter.getQueryValue(filter.value,1);
        query += `&filters[${filterName}][1][value]=${queryValue}`;
        query += `&filters[${filterName}][1][operator]=lte`;
        break;
      case('range'):
        if(filter.value.from === null && filter.value.to === null) break;
          queryValue = filter.getQueryValue(filter.value,0);
          if(queryValue !== null) {
            query += `&filters[${filterName}][0][value]=${queryValue}`;
            query += `&filters[${filterName}][0][operator]=gte`;
          }
          queryValue = filter.getQueryValue(filter.value,1);
        if(queryValue !== null) {
          query += `&filters[${filterName}][1][value]=${queryValue}`;
          query += `&filters[${filterName}][1][operator]=lte`;
        }
        break;
      case('string'): {
        query += `&filters[${filterName}][0][value]=${queryValue}`;
        query += `&filters[${filterName}][0][operator]=${filter.operator}`;
        break;
      }
    }
    return query;
  }

  applyFilters(): void {
    if(this.isLoading) return;
    this.$emit('filtersChange', this.getFilterQuery());
  }

  protected async getItemsCount(){
    const headers: any = { 'x-total-count-only': true }

      if (this.viewedId) {
          headers['Viewed-Context'] = this.viewedId
      }

    const response = await axios.get(`${BACKEND_API_URL}/${this.itemsUrl}?${this.filterQuery}&limit=0`,{
      headers
    });
    this.itemsCount = response.headers['x-total-count'] ? response.headers['x-total-count'] : response.data.totalCount;
  }

  clearAll() {
    for(let key in this.filters) {
      if(Array.isArray(this.filters[key].value)) {
        this.filters[key].value = [];
      } else if(this.filters[key].value?.to) {
        this.filters[key].value.to = '';
        this.filters[key].value.from = '';
      } else {
        this.filters[key].value = '';
      }
    }
    this.selectedDocumentStatusOptions = [];
    this.applyFilters();
  }

  loadDocumentNumberOptions(search: string) {
    this.areDocumentNumberOptionsLoading = true;
    this.$store.dispatch('accounting/getDocumentNumbers', search).then((docs) => {
      this.documentNumberOptions = [];
      docs.forEach((doc: any) => {
        this.documentNumberOptions.push({ label: doc, value: doc });
      });

      this.areDocumentNumberOptionsLoading = false;
    });
  }

  async loadCounterPartyNumberOptions(search: string) {
    this.areCounterPartyNameOptionsLoading = true;
    const counterPartyNames = await this.$store.dispatch('accounting/getCounterPartyNames', search);

    this.counterPartyNameOptions = [];
    counterPartyNames.forEach((doc: any) => {
      this.counterPartyNameOptions.push({ label: doc, value: doc });
    })

    this.$nextTick(() => {
      this.areCounterPartyNameOptionsLoading = false;
    });
  }

  @Watch('filters.documentType.value') onDocumentTypeChange() {
    this.filters.correctedDocumentNumber.value = '';
  }

  @Watch('filters.date.value') onInvestmentDateChange() {
    if(this.filters.date.value && this.filters.date.value[0] && this.filters.date.value[1]) {
      const date = this.filters.date.value[0];
      let dateTime = new Date(date);

      const firstDay = new Date(dateTime.getFullYear(), dateTime.getMonth(), 1);
      const lastDay = new Date(dateTime.getFullYear(), dateTime.getMonth() + 1, 0);
      const formattedFirstDay = `${firstDay.getFullYear()}-${Utils.pad(firstDay.getMonth() + 1, 2)}-${Utils.pad(firstDay.getDate(), 2)}`;
      const formattedLastDay = `${lastDay.getFullYear()}-${Utils.pad(lastDay.getMonth() + 1, 2)}-${Utils.pad(lastDay.getDate(), 2)}`;

      if(formattedFirstDay == this.filters.date.value[0] && formattedLastDay == this.filters.date.value[1]) {
        const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
        const month = monthNames[dateTime.getMonth()];
        this.date = `${month}, ${dateTime.getFullYear()}`;
      } else {
        this.date = '';
      }

    } else {
      this.date = '';
    }
  }
}
