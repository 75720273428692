


























import Component from 'vue-class-component'
import {BPagination} from "bootstrap-vue";
import {Emit} from "vue-property-decorator";

@Component({
  components: { }
})
export default class SygniPagination extends BPagination {
  totalRows: number = 0;
  perPage: number = 10;
  currentPage: number = 0;
  showPageNumberOptions: boolean = false;
  perPageOptions: Array<number> = [10, 20, 50];

  // eslint-disable-next-line no-unused-vars
  changePerPageOption(perPage: number): void {
    this.$emit('changePerPageOption', perPage);
  }

  mounted() {
    this.$on('changePage', (page: number) => {
      this.currentPage = page;
    });
  }

  @Emit('change') emitChange(event: any){
    return event;
  }
}

