import Vue from 'vue'

const screens = {
  xs: 768,
  sm: 920,
  md: 1100,
  lg: 1300,
  xl: 1450,
}

const xs = (val: number) => val >= screens.xs && val <= screens.sm
const sm = (val: number) => val >= screens.sm && val <= screens.md
const md = (val: number) => val >= screens.md && val <= screens.lg
const lg = (val: number) => val >= screens.lg && val <= screens.xl
const xl = (val: number) => val >= screens.xl

const getBreakpoint = (w: number) => {
  if(xs(w)) return 'xs'
  else if(sm(w)) return 'sm'
  else if(md(w)) return 'md'
  else if(lg(w)) return 'lg'
  else if(xl(w)) return 'xl'
  else return 'all'
}

const debounce = (func: Function, wait: number) => {
  let timeout: any;
  return () => {
    const later = () => {
      timeout = null
    }
    const callNow = !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func()
  }
}

const breakpoints = Vue.observable({
  w: window.innerWidth,
  h: window.innerHeight,
  is: getBreakpoint(window.innerWidth)
})

window.addEventListener(
  'resize',
  debounce(() => {
    breakpoints.w = window.innerWidth,
    breakpoints.h = window.innerHeight,
    breakpoints.is = getBreakpoint(window.innerWidth)
  }, 5)
)

export default breakpoints