
























import Vue from 'vue'
import Component from 'vue-class-component'
import TransactionsFilters from '../components/TransactionsFilters.vue';
import TransactionsTable from '../components/TransactionsTable.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import { mapState } from 'vuex';
import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import PortfolioModal from '@/modules/genprox/modules/fund/modules/portfolio/components/PortfolioModal.vue';
import { Watch } from 'vue-property-decorator';
import SygniLoader from '@/components/layout/SygniLoader.vue';

@Component({
  components: { TransactionsFilters, TransactionsTable, SygniContainerTitle, SygniSelect, PortfolioModal, SygniLoader },
  computed: mapState('transactions', {
    transactionsTable: (state: any) => state.transactionsTable,
    isBusy: (state: any) => state.transactionsTableIsBusy,
    perPage: (state: any) => state.transactionsTable.perPage,
  })
})
export default class TransactionsModule extends Vue {
  filtersQueryString: string = '';
  transactionsTable!: any;
  perPage!: number;
  isBusy!: boolean;
  showPortfolioModal: boolean = false;
  isPortfolioModalLoading: boolean = false;
  transactionId: string = '';
  activeIndex?: number = null;
  prevIndex: number = 0;
  nextIndex: number = 0;
  isLoading: boolean = false;

  get selectedDetails() {
    return this.transactionsTable.items;
  }

  get accessModules() {
    return this.$store.getters['genprox/getAccessModules'];
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedId() {
    if(this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company' ? this.viewedCompanyId : '';
    } else {
      return this.activeUserData.context.context !== 'fund' ? this.viewedFundId : '';
    }
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('/company') ? true : false;
  }

  openDetailsModal(id: string) {
    const currentIndex = this.selectedDetails.findIndex((el: any) => el.id == id);
    this.setIndexes(currentIndex);
    this.showPortfolioModal = true;
  }

  detailsModalLoaded() {
    this.isPortfolioModalLoading = false;
  }

  closePortfolioModal() {
    this.showPortfolioModal = false;
    this.activeIndex = null;
    this.transactionId = '';
  }

  setIndexes(index: number) {
    if (this.activeIndex == index) return;

    this.activeIndex = index;
    this.prevIndex = (this.activeIndex == undefined) ? undefined : (Number(this.activeIndex) == 0) ? this.selectedDetails.length - 1 : Number(this.activeIndex) - 1;
    this.nextIndex = (this.activeIndex == undefined) ? undefined : (this.selectedDetails.length <= Number(this.activeIndex) + 1) ? 0 : Number(this.activeIndex) + 1;
    this.isPortfolioModalLoading = true;
  }

  applyFiltersEvent(queryString: string) {
    this.filtersQueryString = queryString;
    (this.$refs.transactionsTable as TransactionsTable).onFiltersChange(queryString);
  }

  setViewedId(id: string): void {
    if (id) {
      this.checkCapitalAvailability(id);
    }
  }

  async checkCapitalAvailability(id: string) {
    this.isLoading = true;
    const leAccessModules = await this.$store.dispatch('genprox/getLegalEntityAccessModules', id);
    if (!(leAccessModules?.capital || leAccessModules?.portfolio)) {
      this.$router.push({
        path: 'access-denied',
        append: true
      })
    } else {
      localStorage.setItem(REDIRECTED_PATH, window.location.pathname)
      if(this.isPortfolioCompany) {
        await this.$store.dispatch('auth/changeViewedCompany', id);
      } else {
        await this.$store.dispatch('auth/changeViewedFund', id);
      }
    }
    this.isLoading = false;
  }

  async beforeMount() {
    this.isLoading = true;
    if (!this.isPortfolioCompany) {
      if (this.activeUserData.context.context != 'fund') {
        await this.$store.dispatch('auth/getViewedFund');
        await this.$store.dispatch('auth/getInvestedFunds');
      }
    } else {
      if(this.activeUserData.context.context != 'company') {
        await this.$store.dispatch('auth/getViewedCompany');
        await this.$store.dispatch('auth/getInvestedCompanies');
      }
    }
    this.isLoading = false;
  }
  
  async mounted() {
    await this.$store.dispatch('transactions/getTransactions', {
      filtersQuery: '',
      viewedContext: this.viewedId
    });
  }

  @Watch('viewedId') onViewedFundIdChange() {
    (this.$refs.transactionsTable as TransactionsTable).onFiltersChange('');
  }

  @Watch('activeIndex') onActiveIndexChange() {
    const activeTransaction = this.selectedDetails[this.activeIndex];

    if (activeTransaction) {
      this.transactionId = activeTransaction.id;
    }
  }
}
