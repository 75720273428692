import Vue from 'vue'
import Component from 'vue-class-component'
import Utils from "@/modules/shared/utils/utils";
import {Watch} from "vue-property-decorator";
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL} from "@/shared/consts";
import {RangeValue} from "@/components/inputs/SygniRangeInput.vue";

@Component
export class FilterMixin extends Vue {

    itemsUrl: string = '';
    isLoading: boolean = false;

    filters: Record<string, Filter> = {};

    itemsCount: number = 0;
    protected getItemsTimeout: any;
    filterQuery: string = '';

    countItems() {
        this.isLoading = true;
        this.filterQuery = this.getFilterQuery();
        clearTimeout(this.getItemsTimeout);
        this.getItemsTimeout = setTimeout(() => {
            this.getItemsCount().then(() => {
                this.isLoading = false;
            });
        }, 800);
    }

    @Watch('filters', {deep: true}) async onFiltersValueChange() {
        this.countItems()
    }
    
    @Watch('itemsUrl') async onItemsUrlChange() {
        this.countItems()
    }

    protected async getItemsCount() {
        const response = await axios.get(`${BACKEND_API_URL}/${this.itemsUrl}?${this.filterQuery}&limit=0`,{
            headers: {'x-total-count-only': true}
        });
        this.itemsCount = response.headers['x-total-count'];
    }

    onMounted(){
        this.onFiltersValueChange();
    }

    createCustomQuery(filter: Filter, filterName: string): string{
        return '';
    }

    getFilterQuery(): string {
        this.filterQuery = '';
        for( const key in this.filters) {
            if (this.filters[key].value && this.filters[key].value !== '') {
                this.filterQuery += this.createSingleFilterQuery(this.filters[key], key);
            }
        }
        return this.filterQuery;
    }

    createSingleFilterQuery(filter: Filter, filterName: string): string {
        let query: string = this.createCustomQuery(filter, filterName);
        if(query !== '') return query;


        if (filter.getQueryValue === FilterFunctions.arrayContain) {
            for (let index = 0; index < filter.value.length; index++) {
                const filterValue = filter.value[index];
                query += `&filters[${filterName}][${index}][operator]=${filter.operator}`
                query += `&filters[${filterName}][${index}][value]=${filterValue}`
            }
            return query
        }

        let queryValue = filter.getQueryValue(filter.value);
        if(queryValue === '') return '';
        switch(filter.type) {
            case('dateRange'):
                if(filter.value[0] === null || filter.value[1] === null) break;
                queryValue = filter.getQueryValue(filter.value,0);
                query += `&filters[${filterName}][0][value]=${queryValue}`;
                query += `&filters[${filterName}][0][operator]=gte`;
                queryValue = filter.getQueryValue(filter.value,1);
                query += `&filters[${filterName}][1][value]=${queryValue}`;
                query += `&filters[${filterName}][1][operator]=lte`;
                break;
            case('range'):
                if(filter.value.from === null && filter.value.to === null) break;
                queryValue = filter.getQueryValue(filter.value,0);
                if(queryValue !== null) {
                    query += `&filters[${filterName}][0][value]=${queryValue}`;
                    query += `&filters[${filterName}][0][operator]=gte`;
                }
                queryValue = filter.getQueryValue(filter.value,1);
                if(queryValue !== null) {
                    query += `&filters[${filterName}][1][value]=${queryValue}`;
                    query += `&filters[${filterName}][1][operator]=lte`;
                }
                break;
            case('string'): {
                query += `&filters[${filterName}][0][value]=${queryValue}`;
                query += `&filters[${filterName}][0][operator]=${filter.operator}`;
                break;
            }
        }
        return query;
    }

    addRemoveArrayValue(filterName: string,value: string): void {
        const array: Array<string> = this.filters[filterName].value;
        if(array.indexOf(value) === -1) {
            array.push(value);
        } else {
            array.splice(array.indexOf(value), 1);
        }
    }

    getArrayValue(filterName: string,value: string): boolean {
        return this.filters[filterName]?.value?.indexOf(value) !== -1;
    }
}

export const FilterFunctions: any = {
    string: (value: string) => { return encodeURIComponent(value) },
    range: (value: RangeValue, index: number) => { return index === 0 ? value.from : value.to},
    dateRange: (value: Array<any>, index: number) => { if(!value[0]){return ''} return index === 0 ? `${value[0]} 00:00:00` : `${value[1]} 23:59:59`},
    source: (value: any) => { return value.id },
    array: (value: Array<string>) => {return encodeURIComponent(value.join(','));},
    arrayContain: (value: Array<string>) => {
        return encodeURIComponent(value.join(','));
    },
}

export const FilterHelpers: any = {
    parseURLFilters: (url: string) => {
        const queryStart = url.indexOf("?") + 1,
            queryEnd   = url.indexOf("#") + 1 || url.length + 1,
            query = url.slice(queryStart, queryEnd - 1),
            pairs = query.replace(/\+/g, " ").split("&"),
            parms: any = {};

        let i: any;
        let n: any;
        let v: any;
        let nv: any;

        if (query === "") return;

        for (i = 0; i < pairs.length; i++) {
            nv = pairs[i].split("=", 2);
            n = decodeURIComponent(nv[0]);
            v = decodeURIComponent(nv[1]);

            if(n.includes('value')) {
                n = n.replace('filters', '');
                n = n.replace('[value]', '');
                // eslint-disable-next-line no-prototype-builtins
                if (!parms.hasOwnProperty(n)) parms[n] = [];
                parms[n].push(nv.length === 2 ? v : null);
            }

        }

        return parms;
    },
}

export interface Filter{
    value: any,
    operator: FilterOperators,
    type: string,
    getQueryValue?: (value: any, index?: any) => string
}


export enum FilterOperators {
    gte = 'gte',
    gt = 'gt',
    eq =  'eq',
    lte =  'lte',
    lt =  'lt',
    in = 'in',
    like =  'like',
    range = 'range',
    contain = 'contain',
    dateRange = 'dateRange',
}
