











import Vue from 'vue'
import Component from 'vue-class-component'
import {Emit, Prop} from "vue-property-decorator";
import SygniInput from "@/components/inputs/SygniInput.vue";

export interface RangeValue {
  from: number | null,
  to: number | null
}

@Component({
  components: {SygniInput}
})
export default class SygniRangeInput extends Vue {

  @Prop() label!: String;
  @Prop({default: () => ({from: null, to: null} as RangeValue) }) value!: RangeValue;
  @Prop() displayFormat!: String;
  modelValue: RangeValue = { from: null, to: null }
  @Prop({ default: false }) disabled: boolean;

  @Emit('input')
  handleInput(value: number, type: string): RangeValue {
    if(type === 'to'){
      this.modelValue.to = !value ? null : value;
    } else {
      this.modelValue.from = !value ? null : value;
    }
    return this.modelValue;
  }

  mounted(){
    this.modelValue = this.value;
  }
}
