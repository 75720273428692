
















































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import { BSpinner, BvTableField } from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import { Watch } from 'vue-property-decorator';
import breakpoints from '@/plugins/breakpoints';
import { mapGetters } from 'vuex';
import { Dictionaries } from '../../portfolio/store/types';

@Component({
  components: {
    SygniCircleButton, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniSquareButton, SygniRectButton, SygniModal
  },
  computed: {
    ...mapGetters('portfolio', {
      dictionaries: 'getDictionaries',
    })
  }
})
export default class TransactionsTable extends SygniTable<any> {

  dictionaries!: Dictionaries;
  refresh: any = null;

  breakpoints = breakpoints;

  tableFields: (BvTableField & { borderless?: Boolean } & { key: string })[] = [
    { key: 'code', sortable: true, class: 'table__head-cell company', label: 'Transaction code' },
    { key: 'date', sortable: true, class: 'table__head-cell', borderless: true, label: 'Transaction date' },
    { key: 'registrationStatus', sortable: true, class: 'table__head-cell', borderless: true, label: 'Registration status' },
    { key: 'registrationDate', sortable: true, class: 'table__head-cell', borderless: true, label: 'Registration date' },
    { key: 'createdAt', sortable: true, class: 'table__head-cell', borderless: true, label: 'Created' },
    { key: 'updatedAt', sortable: true, class: 'table__head-cell right', borderless: true, label: 'Updated' },
  ];

  onRowClicked(item: any) {
    this.openDetails(item);
  }

  openDetailsModal(rowData: any, items: any) {
    this.$emit('openDetailsModal', rowData.id, items);
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('transactions/setTransactionsTableFiltersQuery', filtersQuery);
    if (this.paginationInstance) this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }
      this.$store.commit('transactions/setTransactionsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    if (this.paginationInstance) this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }
      this.$store.commit('transactions/setTransactionsTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  setQuery() {
    this.$store.commit('transactions/setTransactionsTableQuery', this.localTableQuery);
  }

  getIndustryLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.industry.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getStageLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.stage.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getInstrumentClassLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.instrumentClass.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  getInstrumentTypeLabel(value: string) {
    if (!this.dictionaries) return value;

    const label = this.dictionaries.instrumentType.find((el: any) => el.value == value)?.label;
    return label ? label : 'N/A';
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('/company') ? true : false;
  }

  get viewedId() {
    if(this.isPortfolioCompany) {
      return this.activeUserData.context.context !== 'company' ? this.viewedCompanyId : '';
    } else {
      return this.activeUserData.context.context !== 'fund' ? this.viewedFundId : '';
    }
  }

  async getItems() {
    if(!this.isPortfolioCompany) {
      if (!this.viewedFundId && this.activeUserData.context.context != 'fund') {
        await this.$store.dispatch('auth/getViewedFund');
      }
    } else {
      if(!this.viewedCompanyId && this.activeUserData.context.context != 'company') {
        await this.$store.dispatch('auth/geViewedCompany');
      }
    }

    try {
      await this.$store.dispatch('transactions/getTransactions', {
        filtersQuery: this.filtersQuery,
        viewedContext: this.viewedId
      });
    } catch (e) {
      this.$notify({
        type: 'error',
        title: 'Portfolio cannot be loaded.'
      });
    }
  }

  async openDetails(rowData: any) {
    const item = (!rowData.item) ? rowData : rowData.item;

    this.$store.commit(this.setTableBusyMutation, true);
    item.details = await this.$store.dispatch('transactions/getTransactionById', {
      id: item.id,
      viewedContext: this.viewedId
    });

    this.$nextTick(() => {
      this.openDetailsModal(item.details, item.details.items);
      this.$store.commit(this.setTableBusyMutation, false);
    })
  }

  setWidths() {
    const columns: Array<number> = [];
    (this.$refs.transactionsTable as TransactionsTable).$el.querySelectorAll('thead tr .table__head-cell').forEach((column: any) => {
      columns.push(column.offsetWidth);
    })
    this.$nextTick(() => {
      const dataRows = (this.$refs.transactionsTable as TransactionsTable).$el.querySelectorAll('.b-table-details tbody tr');

      dataRows.forEach((dataRow: any) => {
        columns.forEach((width: any, index: number) => {
          if (index == 0) return;
          const element: HTMLElement = dataRow.querySelector(`td:nth-of-type(${index + 1})`);
          element.style.width = `${(index + 1) == columns.length ? width - 20 : width}px`;
        })
      })

    })
  }

  get filtersQuery() {
    return this.$store.getters['transactions/getTransactionsTableFiltersQuery'];
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  async beforeMount() {
    await this.getItems();
    this.onMounted();
    this.setBorderlessTds();
  }

  @Watch('items') onItemsChange(): void {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    if (this.isProcessing) {
      this.$store.commit('transactions/setTransactionsTableBusy', true);
      this.refresh = setTimeout(() => {
        this.onFiltersChange(this.filtersQuery);
      }, 3000);
    }
  }

  @Watch('breakpoints.w') onBreakpointsChange(): void {
    this.setWidths();
  }
}

