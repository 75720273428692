var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-range-input"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "inputs"
  }, [_c('sygni-input', {
    attrs: {
      "displayFormat": _vm.displayFormat,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.handleInput($event, 'from');
      }
    },
    model: {
      value: _vm.value.from,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "from", $$v);
      },
      expression: "value.from"
    }
  }), _vm._v(" - "), _c('sygni-input', {
    attrs: {
      "value": _vm.value.to,
      "displayFormat": _vm.displayFormat,
      "disabled": _vm.disabled
    },
    on: {
      "input": function input($event) {
        return _vm.handleInput($event, 'to');
      }
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }