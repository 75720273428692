var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "transactions-module"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('transactions-filters', {
    on: {
      "filtersChange": _vm.applyFiltersEvent,
      "setViewedId": _vm.setViewedId
    }
  }), _c('div', {
    staticClass: "transactions-module__container"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', [_vm._v("Transactions")]), _c('transactions-table', {
    ref: "transactionsTable",
    attrs: {
      "show-page-number-options": true,
      "tableData": _vm.transactionsTable,
      "tablePerPageMutation": "transactions/setTransactionsTablePerPage",
      "setTableBusyMutation": "transactions/setTransactionsTableBusy",
      "busy": _vm.isBusy,
      "perPage": _vm.perPage,
      "getItemsAction": "transactions/getTransactionsTableItems"
    },
    on: {
      "openDetailsModal": _vm.openDetailsModal
    }
  })], 1)])])]), _c('portfolio-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showPortfolioModal,
      expression: "showPortfolioModal"
    }],
    attrs: {
      "isLoading": _vm.isPortfolioModalLoading,
      "showNavigation": _vm.selectedDetails.length > 1,
      "transactionId": _vm.transactionId
    },
    on: {
      "close": _vm.closePortfolioModal,
      "loaded": _vm.detailsModalLoaded,
      "prev": function prev($event) {
        return _vm.setIndexes(_vm.prevIndex);
      },
      "next": function next($event) {
        return _vm.setIndexes(_vm.nextIndex);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }