




import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniPagination from "@/components/table/SygniPagination.vue";
import { Prop, Watch} from "vue-property-decorator";
import { BTable, BvTableField} from "bootstrap-vue";
import Vue from 'vue';
import {TableQuery} from "@/modules/shared/utils/TableQuery";
import {TableData} from "@/modules/genprox/modules/profile/store/types";
import _ from 'lodash';

@Component({
  components: {ExpandCollapse}
})
export default class SygniTable<T> extends BTable {
  items!: Array<T & Record<string, any>>;
  scrollingEnabled: boolean = true
  scrollbarRef: string = null

  @Prop({default: false}) showPageNumberOptions!: boolean;
  @Prop({default: 'genprox/setBusyTable'}) setTableBusyMutation!: string;
  @Prop() tablePerPageMutation?: string;
  @Prop({}) busy!: boolean;
  @Prop({}) perPage?: number;
  @Prop({default: false}) infinityScroll: boolean;
  @Prop({default: true}) showPagination!: boolean;

  @Watch('showPagination')onShowPaginationChange(): void {
    (this.paginationInstance.$el as HTMLDivElement).style.display = this.showPagination ? 'visible' : '';
  }

  @Prop() tableData!: TableData<T>
  @Watch('tableData', {deep: true, immediate: true}) onTableDataChange(){
      this.paginationInstance.totalRows = this.tableData?.totalCount;
      this.items = this.tableData?.items;
      this.perPage = this.tableData?.perPage ?? 10;

      this.paginationInstance.perPage = this.perPage;
  }

  @Watch('busy') setBusy(): void {
    this.isBusy = this.busy;
  }

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [];
  localTableQuery: TableQuery = null;

  sortBy: string = '';
  sortDesc: boolean = false;

  paginationInstance: SygniPagination = {} as SygniPagination;
  isBusy: boolean = false;

  onSortChange(): void {
    this.$nextTick( () => {
      const sign = this.sortDesc ? '-' : '';
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.sorting = { order: sign, name: this.sortBy};
      this.setQuery();
      this.getItems();
    });
  }

  setQuery(): void {
    // To implement in component that extends SygniTable
  }
  // eslint-disable-next-line no-unused-vars
  async getItems(keepExistingCheckboxes: boolean = false): Promise<void> {
    // To implement in component that extends SygniTable
  }

  created () {
    this.$root.$on('clearTablesData', () => {
      this.clearTableData();
    })
  }

  onMounted() {
    if(this.tableFields){
      this.setBorderlessTds();
      this.addSortingIcons();
    }
    this.addHoverEffects();
    if(this.showPagination && !this.infinityScroll){
      this.addPagination();
    }
    if (this.infinityScroll && this.scrollbarRef) {
      (this.$refs[this.scrollbarRef] as any)?.SimpleBar?.getScrollElement()?.addEventListener('scroll', _.debounce((e: any) => {
        const offsetY = 10
        if (
          Math.floor(e?.target?.scrollTop || 0) + offsetY + Math.round(e?.target?.clientHeight || 0) >=
          e?.target?.scrollHeight
        ) {
          this.loadMoreItems()
        }
      }, 10))
    }
  }

  clearTableData() {
    if (this.localTableQuery && this.paginationInstance) {
      this.localTableQuery = new TableQuery().setQuery(this.tableData?.query);
      this.localTableQuery.limit = 10
      this.localTableQuery.offset = 0
      this.setQuery()
      this.paginationInstance.perPage = 10

      if (this.tablePerPageMutation) {
        this.$store.commit(this.tablePerPageMutation, 10)
      }
    }
  }

  beforeDestroy() {
    if (this.infinityScroll && this.scrollbarRef) {
      (this.$refs[this.scrollbarRef] as any)?.SimpleBar?.getScrollElement()?.removeListener('scroll')
    }
  }

  @Watch('items')
  setBorderlessTds(): void {
    this.$store.commit(this.setTableBusyMutation, false);
    this.scrollingEnabled = true
    setTimeout( () => {
      let tds: Array<HTMLTableCellElement> = [];
      this.$el?.querySelector('table')!.querySelector('tbody').
      querySelectorAll(':scope > tr:not([class=b-table-details])').forEach( (tr: Element) => {
        tds = tds.concat(Array.from(tr.querySelectorAll('td')));
      });

      let iterator = 0;
      this.tableFields.forEach( (field, index) => {
        if(field.borderless) {
          iterator = 0;
          let borderlessTd = tds[iterator * this.tableFields.length + index];
          while(borderlessTd){
            iterator++;
            borderlessTd.classList.add('borderless');
            borderlessTd = tds[iterator * this.tableFields.length + index];
          }
        }
      });
    }, 250);
  }


  addPagination(): void {
    const ComponentClass = Vue.extend(SygniPagination);
    this.paginationInstance = new ComponentClass<SygniPagination>();
    this.paginationInstance.$mount();


    this.$nextTick(() => {
      const currentPage = (this.tableData?.query?.offset / this.tableData?.query?.limit) + 1
      this.paginationInstance.currentPage = currentPage
    })
    
    this.paginationInstance.showPageNumberOptions = this.showPageNumberOptions;
    this.paginationInstance.perPage = this.tableData.perPage;
    this.paginationInstance.totalRows = this.tableData.totalCount;
    this.paginationInstance.$on('change', (currentPage: number) => {
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = this.tableData.perPage * (currentPage - 1);
      this.setQuery();
      this.getItems();
    });
    this.paginationInstance.$on('changePerPageOption', (perPage: number) => {
      if(this.tablePerPageMutation) this.$store.commit(this.tablePerPageMutation, perPage);
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = 0;
      this.setQuery();
      this.getItems();
      this.changePerPageOption();
    });
    const tableElement: Element = this.$el.querySelector('.sygni-b-table') as Element;

    const isAccountingTable = this.$el.classList.contains('accounting-table');
    const isWhistleblowerTable = this.$el.classList.contains('whistleblower-table');
    const isPortfolioTable = this.$el.classList.contains('portfolio-table');
    const isTransactionsTable = this.$el.classList.contains('transactions-table');
    const isProductsTable = this.$el.classList.contains('products-table');
    const isCampaignsTable = this.$el.classList.contains('campaigns-table');
    const isSubscriptionsTable = this.$el.classList.contains('subscription-table');
    const isAdvisorsTable = this.$el.classList.contains('advisors-table');

    if(isAccountingTable) {
      this.$el.querySelector('.accounting-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isWhistleblowerTable) {
      this.$el.querySelector('.whistleblower-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isPortfolioTable) {
      this.$el.querySelector('.portfolio-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isTransactionsTable) {
      this.$el.querySelector('.transactions-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isProductsTable) {
      this.$el.querySelector('.products-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isCampaignsTable) {
      this.$el.querySelector('.campaigns-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isSubscriptionsTable) {
      this.$el.querySelector('.subscription-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if(isAdvisorsTable) {
      this.$el.querySelector('.advisors-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else {
      this.$el.insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    }
  }

  changePerPageOption(): void {
    this.$emit('changePerPageOption', this.tableData.perPage);
  }

  addHoverEffects(): void {
    this.$el.querySelectorAll('tr').forEach((el) => {
      el.addEventListener('mouseenter', (event) => {
        if(el !== event.target) return;
        this.onRowEnter(el);
      });
    }); 
    this.$el.querySelectorAll('tr').forEach((el) => {
      el.addEventListener('mouseleave', (event) => {
        if(el !== event.target) return;
        this.onRowLeave(el);
      });
    });
  }

  addSortingIcons(): void {
    const ths = this.$el.querySelectorAll('th');
    const iconsHTML: string = `<div class="th__arrows">
        <img class="arrow-up" src="${require('@/assets/icons/arrow.svg')}">
        <img class="arrow-down" src="${require('@/assets/icons/arrow.svg')}"></div>`

    this.tableFields.forEach( (field: any, index: number) => {
      if(field.sortable && ths[index]) {
        (ths[index].firstChild as HTMLElement).innerHTML += iconsHTML;
      }
    });
  }

  onRowEnter(rowElement: HTMLTableRowElement){
    rowElement.classList.add('hover');
  }
  onRowLeave(rowElement: HTMLTableRowElement){
    rowElement.classList.remove('hover');
  }

  loadMoreItems() {
    if (this.infinityScroll && this.scrollingEnabled) {
      const perPage = this.tableData?.perPage || 20
      const total = Number(this.tableData?.totalCount || 0)
      const totalPages = Math.ceil(total / perPage)
      const currentPage = Math.ceil((this.items?.length / perPage) >= 1 ? this.items?.length / perPage : 1)

      if (currentPage < totalPages) {
        this.$store.commit(this.setTableBusyMutation, true);
        this.scrollingEnabled = false
        this.localTableQuery = new TableQuery().setQuery(this.tableData?.query);
        if (total > (perPage * currentPage)) {
          this.localTableQuery.limit = perPage * (currentPage + 1);
        }
        this.localTableQuery.offset = 0
        this.setQuery();
        this.getItems(true);
      }
    }
  }

  resetItems() {
    if (this.infinityScroll && this.scrollbarRef) {
      (this.$refs[this.scrollbarRef] as any).SimpleBar.getScrollElement().scrollTop = 0
    }
    this.localTableQuery = new TableQuery().setQuery(this.tableData?.query);
    this.localTableQuery.limit = this.tableData?.perPage || 20
    this.localTableQuery.offset = 0
    this.setQuery();
    this.getItems();
  }
}

