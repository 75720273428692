var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-pagination"
  }, [_vm.showPageNumberOptions ? _c('div', {
    staticClass: "sygni-records"
  }, [_c('p', {
    staticClass: "sygni-records__label"
  }, [_vm._v("Show records:")]), _c('div', {
    staticClass: "sygni-records__options"
  }, _vm._l(_vm.perPageOptions, function (option) {
    return _c('button', {
      key: option,
      class: ['sygni-records__option', option == _vm.perPage ? 'active' : ''],
      on: {
        "click": function click($event) {
          return _vm.changePerPageOption(option);
        }
      }
    }, [_vm._v(_vm._s(option))]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "sygni-pagination__wrapper"
  }, [_c('b-pagination', _vm._g(_vm._b({
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "limit": 8,
      "first-text": "First",
      "last-text": "Last"
    },
    on: {
      "change": _vm.emitChange
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  }, 'b-pagination', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners))], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }