var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-select"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('multiselect', _vm._g(_vm._b({
    ref: "multiselect",
    attrs: {
      "options": _vm.options,
      "value": _vm.optionValue,
      "label": _vm.optionsLabel,
      "track-by": "label",
      "maxHeight": 205,
      "showLabels": false
    },
    on: {
      "input": _vm.handleInput,
      "search-change": _vm.handleSearch
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var values = _ref.values;
        return [values.length && _vm.multiple && !_vm.displayValues ? _c('span', {
          staticClass: "multiselect__single"
        }, [_vm._v(" " + _vm._s(values.length) + " options selected ")]) : _vm._e(), values.length && _vm.multiple && _vm.displayValues ? _c('span', {
          staticClass: "multiselect__single"
        }, _vm._l(values, function (item) {
          return _c('span', {
            key: item.value
          }, [_vm._v(_vm._s(item.label))]);
        }), 0) : _vm._e()];
      }
    }, {
      key: "tag",
      fn: function fn(props) {
        return [_vm._v(_vm._s(props))];
      }
    }, {
      key: "option",
      fn: function fn(_ref2) {
        var option = _ref2.option;
        return [_c('div', {
          class: ['option', option !== null && option !== void 0 && option.disabled ? 'disabled' : '']
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "disabled": true,
            "value": _vm.isSelected(option)
          }
        }), _c('div', {
          staticClass: "text"
        }, [_vm._v(" " + _vm._s(option.label) + " ")])], 1)];
      }
    }])
  }, 'multiselect', Object.assign({}, _vm.removeKey(Object.assign({}, _vm.$props), 'value'), _vm.removeKey(Object.assign({}, _vm.$attrs), 'value')), false), _vm.removeKey(Object.assign({}, _vm.$listeners), 'input')), [!_vm.isFiltered && _vm.selectAll ? _c('template', {
    slot: "beforeList"
  }, [_c('div', {
    staticClass: "sygni-select__toggle-option"
  }, [_c('sygni-link-button', {
    staticClass: "gn-secondary",
    attrs: {
      "type": "simple"
    },
    on: {
      "click": _vm.toggleAll
    }
  }, [_vm._v(_vm._s(_vm.toggleAllText))])], 1)]) : _vm._e()], 2), _c('sygni-input-error', {
    attrs: {
      "validation": _vm.validation
    }
  }, [_vm._t("error")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }